import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { ITextBlockProps } from 'gatsby-theme-husky/src/components/TextBlock/model';

const TextBlock: FC<ITextBlockProps> = ({ html, className, element = 'div' }) => {
  return (
    <DangerouslySetInnerHtml className={`text-block ${className}`} element={element} html={html} />
  );
};

export default TextBlock;
