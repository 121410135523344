import React from 'react';
import ArticleSubMenuItem from 'gatsby-theme-husky/src/components/ArticleSubMenuItem';
import ARTICLES from 'gatsby-theme-husky/src/shared/static/content';

const ArticleSubMenu = ({ content }) => (
  <>
    {content
      .filter(({ structure }) => structure === ARTICLES.SECTION)
      .map(({ properties: { articleSectionTitle } }) =>
        articleSectionTitle ? (
          <ul className="article-submenu__list">
            <ArticleSubMenuItem
              key={articleSectionTitle}
              name={articleSectionTitle}
              linkToSection={articleSectionTitle}
            />
          </ul>
        ) : null
      )}
  </>
);

export default ArticleSubMenu;
